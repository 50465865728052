<template>
  <router-view></router-view>
</template>
<script setup>
  import { onMounted } from 'vue';
  import router from '@/router';
  import { useRouter } from 'vue-router'
  onMounted(() => {
    if (_isMobile()) {
      // 跳转至手机端路由
      router.push({name:"innerH5"});
    } else {
      router.push({name:"inner"});
    }
  })
  let _isMobile = () => {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  }
  let fun = ((document, window) => {
    let deviceWidth = document.documentElement.clientWidth;
    if (deviceWidth > 768) {
      if(deviceWidth < 1260){
        deviceWidth = 1260  
      }
      document.documentElement.style.fontSize = deviceWidth / 19.20 + 'px';
    } else {
      document.documentElement.style.fontSize = deviceWidth / 7.50 + 'px';
    }
  })
  fun(document, window);

</script>
<style lang="scss">
html {
  box-sizing: border-box;
}
*,*::before,*::after {
  box-sizing: inherit;
}
body,html{
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
